<template>
  <div id="refunds">
    <b-row class="mb-5">
      <b-col>
        <h1>{{ $t('Refunds') }}</h1>
        <h5 class="text-primary">
          {{ $t('refunds.shopRefunds') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          ref="refInvoiceListTable"
          :items="allRefunds"
          :busy="isBusy"
          responsive
          :fields="tableColumns"
          primary-key="id"
          select-mode="single"
          show-empty
          :empty-text="$t('emptyText')"
          selectable
          @row-selected="rowSelect"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>{{ $t('load') }}...</strong>
            </div>
          </template>
          <template #cell(user_id)="data">
            <div>
              <b-link
                :to="{
                  name: 'final-users-edit',
                  params: { id: data.item.node.user.id }
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                @{{ cleanUsername(data.item.node.user.username) }}
              </b-link>
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              <b-badge
                pill
                :variant="data.item.node.status == 'PEN' ? 'warning' : data.item.node.status == 'COM' ? 'success' : 'danger'"
                class="text-capitalize"
              >
                {{ status[data.item.node.status] }}
              </b-badge>
            </div>
          </template>
          <template #cell(reason)="data">
            <!-- style="overflow-y: scroll; max-height: 130px;" -->
            <div id="">
              <span class="acortar_parrafo">
                {{ data.item.node.reason }}
              </span>
            </div>
          </template>
          <!--  <template #cell(vendor_id)="data">
                        <div>

                            {{ data.item.node.vendor.vendorName }}
                        </div>
                    </template> -->

          <template #cell(amount)="data">
            <div>
              {{ format(format(data.item.node.amount)) }} {{ currencyType[currency] }}
            </div>
          </template>
          <template #cell(payment)="data">
            <div>
              {{ methodPayment[data.item.node.paymentGateway] }}
            </div>
          </template>
          <template #cell(createdAt)="data">
            <div>
              {{ fechaFormato(data.item.node.createdAt) }}
            </div>
          </template>
          <template #cell(refund_creator)="data">
            <div>
              <b-link
                :to="{
                  name: 'administrators-edit',
                  params: { id: data.item.node.refundCreator.id }
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.node.refundCreator.username }}
              </b-link>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex ">
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          @input="fetchData"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BSpinner,
  BTable,
  BBadge,
  BLink,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import {
  messageError, utf8ToB64, isEmpty, cleanUsername,
} from '@/store/functions'

import { getUserData } from '@/auth/utils'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BTable,
    BLink,
    BBadge,
    BPagination,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: getUserData(),
      refunds: null,
      isBusy: false,
      allRefunds: [],
      vendor: '',
      allVendors: [],
      isEmpty,
      filterDate: '',
      currency: null,
      tableColumns: [
        { key: 'user_id', label: 'Cliente' },
        { key: 'status', label: this.$t('editContent.status') },
        { key: 'reason', label: 'Razón' },
        // { key: "vendor_id", label: "Vendedor" },
        { key: 'amount', label: 'A devolver' },
        { key: 'payment', label: 'Forma de pago' },
        { key: 'createdAt', label: 'Fecha' },
        { key: 'refund_creator', label: 'Creador' },
      ],
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: this.$t('argentinePeso'),
        isInternal: false,
      },
      methodPayment: {
        STR: 'Stripe',
        APP: 'Apple InAppPurchase',
        GOW: 'Google InAppPurchase',
      },
      status: {
        PEN: this.$t('pending'),
        COM: this.$t('completed'),
        CAN: this.$t('cancelled'),
      },
      filterStatus: null,
      rows: 0,
      perPage: 20,
      allVusers: [],
      vUser: null,
      allProfiles: [],

      creator: null,
      currentPage: 1,
    }
  },
  watch: {
    filterDate() {
      this.fetchData()
    },
    filterStatus() {
      this.fetchData()
    },
    vUser() {
      this.fetchData()
    },
    creator() {
      this.fetchData()
    },
  },
  mounted() {
    try {
      this.vendor = this.userData.profile.profileVendor.edges[0].node.id
    } catch (error) {
      this.vendor = ''
    }
    this.getVusers()
    this.getProfiles()
    this.getPayMethod()
    this.fetchData()
  },
  methods: {
    format(num) {
      const reg = /\d{1,3}(?=(\d{3})+$)/g
      return (`${num}`).replace(reg, '$&.')
    },
    cleanUsername,
    rowSelect(data) {
      const { id } = data[0].node
      this.$router.push({ name: 'edit-vendor-refunds', params: { id } })
    },

    routeToViewWizardRefunds() {
      this.$router.push({ name: 'create-vendor-refunds' })
    },

    fetchData() {
      this.isBusy = !this.isBusy

      const query = `
            {
                allRefund(
                    first:${this.perPage},
                    ${this.vendor != null ? `vendor:"${utf8ToB64(`id:${this.vendor}`)}"` : ''}
                    offset:${this.perPage * (this.currentPage - 1)},
                    ${this.filterDate !== '' ? `createdAt:"${this.filterDate},"` : ''}
                    ${this.filterStatus != null ? `status:"${this.filterStatus}",` : ''}
                    ${this.vUser != null ? `user:"${utf8ToB64(`id:${this.vUser}`)}",` : ''}
                    ${this.creator != null ? `refundCreator:"${utf8ToB64(`id:${this.creator}`)}",` : ''}

                    
                    client: "${this.userData.profile.client.id}") {
                        totalCount
                        edges {
                        node {
                            id
                            status
                            user {
                                id
                                username
                            }
                            amount
                            paymentGateway
                            reason
                            date
                            paymentId
                            refundCreator {
                                id
                                username
                            }
                            client {
                                id
                                name
                            }                          
                            vendor {
                                id
                                vendorName
                                name
                                lastName
                                vendorImage
                                imageVendorUrl                                
                            }                       
                            createdAt
                            }
                        }
                    }
                }
            `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)
          this.allRefunds = result.data.data.allRefund.edges

          this.rows = result.data.data.allRefund.totalCount
          this.isBusy = !this.isBusy
        })
        .catch(err => { console.log(err) })
    },
    getProfiles() {
      const query = `{
                allProfiles(client:"${this.userData.profile.client.id}",refund:true) {               
                    edges {
                    node {
                        id
                        username
                        userRefund {
                        edges {
                            node {
                            id
                            }
                        }
                        }
                    }
                    }
                }
                }`

      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.allProfiles = result.data.data.allProfiles.edges
        }).catch(err => {
          console.log(err)
        })
    },
    getVusers() {
      const query = `{
                allVusers(client:"${this.userData.profile.client.id}",refund:true) {               
                    edges {
                    node {
                        id
                        username
                        userRefund {
                        edges {
                            node {
                            id
                            }
                        }
                        }
                    }
                    }
                }
                }`

      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.allVusers = result.data.data.allVusers.edges
        }).catch(err => {
          console.log(err)
        })
    },
    getVendor() {
      const query = `{
            allVendors(client:"${this.userData.profile.client.id}",refund:true) {
                totalCount
                edges {
                    node {
                        id
                        name
                        vendorName
                        
                        vendorRefund {
                        edges {
                            node {
                            id
                            }
                        }
                        }                
                    client {
                        id
                        name
                    }
                    }
                }
                }
            }`

      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.allVendors = result.data.data.allVendors.edges
        }).catch(err => {
          console.log(err)
        })
    },
    clickPickr() {
      document.getElementById('pickr').click()
    },

    getPayMethod() {
      axios
        .post('', {
          query: `
                query{
                client(id:"${this.userData.profile.client.id}"){
                    externalPayMethod
                    currency
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.currency = res.data.data.client.currency
        })
        .catch(() => {
        })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    getImage(data) {
      let path = '@/assets/images/backend/nocover.jpeg'
      if (data.length !== 0) {
        data.forEach(element => {
          path = element.http_path
        })
      }
      return path
    },
    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrl(folder) {
      return folder.imageVendorUrl == null || folder.imageVendorUrl.length === 0
        ? noCover
        : folder.imageVendorUrl
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#refunds .actions {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(0 15 51 / 73%);
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

#refunds .cardContent img {
    height: 11vw;
    object-fit: cover;
    /*width: 100%;
        height:  100%;
        object-fit: cover;*/
}

#refunds .card-body {
    padding: 1rem;
}

#refunds .card-body h4 {
    font-size: 1rem !important;
}

#refunds .card-header {
    padding: 1rem;
}

#refunds .card-header h4 {
    font-size: 1rem !important;
}

#refunds .card-body .actions {
    display: -webkit-flex;
    display: flex;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 2.75rem;
    padding-right: 0.34375rem;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;

    justify-content: space-between;
}

/*
#refunds #acortar {
    overflow: hidden;
    text-overflow: ellipsis;
}

#refunds #acortarPadre {
    overflow: hidden;
    white-space: nowrap;
} */

#acortar {
    overflow: hidden;
    text-overflow: ellipsis;
}

.acortar_parrafo {
    display: block;
    display: -webkit-box;
    font-size: 14px;
    line-height: 1.4;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
